body {
    background-image: url("./images/MHAPortalSignInMobile.jpg");
    background-size:cover;
}




#div-login-container {
  margin: 100px auto 8px;
  width: 500px;
  height: auto;
  min-width: 300px;
  overflow: auto;
  position: relative;
  background-color: #ffffff;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd #ddd #d8d8d8;
  box-shadow: 0 2px 0 hsla(0, 0%, 68.6%, 0.12);
  padding: 42px;
}

@media only screen and (min-width: 600px) {
  body {
    background-image: url("./images/MHAPortalSignInDesktop.jpg");
  }
  #div-login-container {
    width:500px;
  }
}


h2.signin {
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 15px;
  padding: 0;
  text-align: center;
  text-transform: none;
  font-weight: 700;
  color: #2f3f4a;
}

input.signin-text {
  border-radius: 3px;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 6px 8px;
  line-height: 16px;
  border: none;
  background: transparent;
  box-sizing: border-box;
  font-size: 13px;
}

div.form-label {
  font-weight: 700;
  padding: 7px 10px 7px 0;
  line-height: 16px;
  color: #aaa;
  box-sizing: border-box;
}

.input-fix {
  height: 40px;
  border: 1px solid #bbb;
  display: inline-block;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
}

.btn-signin {
  color: #fff;
  background-color: #1662dd;
  background: -o-linear-gradient(top, #1662dd 0, #155ccf 100%);
  background: -ms-linear-gradient(top, #1662dd 0, #155ccf 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1662dd),
    to(#155ccf)
  );
  background: -webkit-linear-gradient(#1662dd, #155ccf);
  background: linear-gradient(#1662dd, #155ccf);
  border-color: #0f4397 #0f4397 #0e3f8e;
  display: block;
  width: 100%;
  height: 50px;
  line-height: 36px;
  padding: 0 15px;
  border: 1px solid;
  border-radius: 3px;
}

#input-container {
  margin-bottom: 10px;
}
