
#content-search {
    text-align: right;
    position:relative;
    top:-5px;
}

#feature-title {
    color: #F5F5F5;
    font-size:28px;
}

#content-header {
    background-color: #003366;
    padding: 10px 30px 10px 30px;
    line-height: 28px;
    vertical-align: middle;
    height: 60px;
    border-bottom: #DE6B00 10px solid;
}

#content-header .fa-search{
    background-color: #015CAB
}

#content-header .form-control{
    height:40px;
    border-radius:3px;
}