.product-details{
    margin-top:10px;
}


#disclaimerMessageFakeTab {
    cursor: default pointer;
    background-color: #f5f5f5;
    color:black;
}

body {
    background-color: #f5f5f5 !important;
   
}

.extra{
    height:100px;
}

.whiteSpace {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: #f5f5f5;
}


.product-portfolio .pb-5{
    padding-bottom:0 !important;
}

