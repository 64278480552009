.sidebar-nav {
  margin: 0;
  padding: 0;
  width: 60px;
  overflow: hidden;
  background-color: #015cab;
  padding-top: 10px;
  transition: 0.5s 0.5s;
  z-index: 10000;
  height: 100%;
  position: absolute;
}

#button-tooltip {
  z-index: 10001;
}
.sidebar-nav .grayed,
.sidebar-nav .grayed:hover {
  color: grey;
}
.sidebar-nav a,
.sidebar-nav button {
  display: block;
  text-align: left;
  outline: none;
  border: none;
  white-space: nowrap;
  background: none;
  color: #f5f5f5;
  outline: none;
  padding: 16px 5px 16px 9px;
  cursor: pointer;
  width: 90%;
  margin: auto;
  text-decoration: none;
  font-weight: 300;
}
.sidebar-nav a:not([href]),
.sidebar-nav a:not([href]):hover {
  color: #f5f5f5;
}

.sidebar-nav a:hover,
.sidebar-nav button:hover {
  background-color: hsla(0, 0%, 78%, 0.2);
  color: #f5f5f5;
  text-decoration: none;
}

.sidebar-nav a img,
.sidebar-nav a svg,
.sidebar-nav button svg {
  margin-right: 18px;
  margin-left: 5px;
}

.sidebar-nav button i {
  margin-left: 5px;
  font-size: 24px;
}

.sidebar-nav:hover {
  width: 420px;
  overflow-y: auto;
}

.sub-menu {
  padding-left: 50px;
  transition: 0.35s;
  overflow: hidden;
  max-height: 0;
  font-size: 14px;
  visibility: hidden;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  -moz-transition: 0.6s;
  -o-transition: 0.6s;
}
.sub-menu.show {
  max-height: fit-content;
  visibility: visible;
}
