/* Provide sufficient contrast against white background */
.home-wrapper {
  padding: 10px;
}
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.full-sisense-feature{
  height:100%;
}

.form-row .btn-primary {
 
  border-color: #1861ac;
  font-family: sans-serif;
  font-size:16px;
  color:#F8F9FA;
  background-color: #015CAB;
  padding:5px;
  /* padding-left:10px; 
  padding-right:10px; */
}
.form-row .results{
  padding-left:10px;
  padding-right:10px;
}
#main .form-row .reset-btn{
  color: #015CAB;
  background-color: #F5F5F5; 
}
.form-row ::placeholder {
  color: #868E96;
  opacity: 1; /* Firefox */
}

input[type="checkbox"] {
  visibility: hidden;
}

input[type="checkbox"] + label:before {
  border: 1px solid #333;
  content: "\00a0";
  border-radius: 4px;
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  background:#f5f5f5;
  margin: 0 .25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
}
input[type="checkbox"]:checked + label:before {
  background: #015CAB;
  color: #fff;
  content: "\2713";
  text-align: center;
}

.form-row .form-check{
  padding-left:0;
}

.form-row .reset-wrap{
  max-width:7.5%;
}

.form-row :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #868E96;


  
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.form-row .form-date select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
span.date-wrap:after {
  content:"\f073 ";
  font-family: FontAwesome;
  color: #015CAB;
  position: absolute; 
  right: 10px; 
  top: 35px;
  z-index: 1;
  width: 10%;
  height: 100%;  
  pointer-events: none;    
}

.form-row ::-ms-input-placeholder { /* Microsoft Edge */
  color: #868E96;

}

.dashboard-toggle{
  position:fixed;
  top:109px;
  right:30px;
  color:#F5F5F5
}

.dashboard-toggle label{
  padding:10px;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: sans-serif;
  font-size: 14px;
  flex: 1;
}
.action-link-with-icon {
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}
#content {
  height: 100vh;
  width: 100%;
}

#non-header{
  position:relative;
}
.flex-fill {
  flex: 1;
}
#main-content {
  background-color: #f5f5f5;
  height: calc(100% - 40px);
  margin-left:60px;
}

#main-content .data-table-wrapper{
  width:100%;
}

#main {
  background-color: #f5f5f5;
  padding:10px;
  height: calc(100% - 40px);
  margin-left:60px;
}

#main .data-table-wrapper{
  width:100%;
}
/*content in layout*/
.feature {
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
  display: flex;
  flex-flow: column;
  width: 100%;
}

/*form button*/
.inline-show-result-button {
  /*margin-top: 32px;*/
  height: 38px;
  background-color: #015cab;
}

@media (min-width: 1440px) {
  .container {
    max-width: none;
  }
}

/* modal width */
.modal-90w {
  width: 90%;
  max-width: none !important;
}

/* clickable link with no href */
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: #015cab;
}

.link-button:hover,
.link-button:focus {
  text-decoration: underline;
}

select option:disabled {
  font-style: italic;
  background-color: #cccccc;
}

.nav-tabs .nav-item {
  margin-bottom: 0px !important;
}



a.nav-link, .nav-link {
  background-color: #015cab;
  color: #ffffff;

  /* border: 0; */
  border-radius: 0.25rem;
  font-family:sans-serif;
  font-size:14px;
}

.nav-link{
  border:0;
  margin: 2px;
}

.nav-tabs{
  height: 55px;
  padding: 0px 30px 0px 30px;
  background-color: #CFCFCF;
  margin: 0px !important;
  position: relative;
  bottom: 10px;
  right: 10px;
  align-items: flex-end;
  width: calc(100% + 20px);
}
a.nav-link{
  font-size:16px;
  padding: 8px 16px;
  border-radius:4px 4px 0 0;
  height:42px;
  margin:0;
  background-color:#CFCFCF;
  color:#015CAB;
  border-color: #CFCFCF #CFCFCF  #F5F5F5!important; 
}

a.nav-link.active {
  background-color:#F5F5F5 !important;
  color:#495057 !important;
  font-size:16px;
}

.gray-nav-link {
  background-color: #015cab;
  color: lightgray;
  margin: 2px;
  border: 0 !important;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem
}

ul.ul-no-decor {
  list-style-type: none;
  padding-inline-start: 0;
}

.link-button.no-focus {
  outline: none;
}

/*comment output if you want overlay during development*/
/*body > iframe {
    display: none !important;
}*/

#button-tooltip a{
  color:  #ffffff
}

.form-row .form-label {
  font-size:12.8px;
  font-weight:700;
  color:#212529;
}

input[type=checkbox]:checked  {
  color: #f00;
  font-style: normal;
} 

.modal-footer{
  justify-content: flex-end;
}

.modal-header{
  text-align:left;
  padding:1rem;
}

.modal-x{
  color: #0366d6;
  position: relative;
  font-size: 20px;
}

.modal-x-container{
  text-align:right;
}

.modal-list-item{
  margin-bottom:5px;
  margin-top:5px;
}

.disclaimer{
  color:#FFC107;
}

.btn-secondary{
  background-color:#fff;
}