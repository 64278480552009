body {
    background-image: url("../images/MHAPortalSignInMobile.jpg");
    background-size:cover;
}




#maintenance-container {
  margin: 8% auto 8px; 
  width: 810px;
  height: auto;
  /* min-width: 300px; */
  overflow: auto;
  position: relative;
  background-color: #034691;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #F8F9FA;
  padding: 60px;
  color:#F8F9FA;
  opacity:0.85
}

#maintenance-container .display-3{
    margin-top: 20px;
    margin-bottom:20px;
}

#maintenance-container h3{
    line-height:26.88px;
    font-size:22.4px;
}
@media only screen and (min-width: 600px) {
  body {
    background-image: url("../images/MHAPortalSignInDesktop.jpg");
  }
  #div-login-container {
    width:500px;
  }
}


