

/* header{
    z-index:1000;
} */

.navbar-nav{
    height: 100%;
}

#screen-header {
    min-height: 90px;
    width: 100%;
    background-color: #FFFFFF;
    padding: 10px 30px 10px 30px;
    color: #015CAB;
}


header .navbar .nav-item{
    font-size:12px;
}

.location-extra-details{
    /* position:fixed   ; */
    text-align:center;
}

.location-name{
    display:block;
}

#screen-header-location, #screen-header-user, #screen-header-help{
    align-items:center;
}

#screen-header-location a, #screen-header-user a, #screen-header-help a{
    color: #015CAB;
    background: #FFF;
    margin-left: auto;
    margin-right: auto;
    text-align:center;
}

.avatar-wrapper {
    display: inline-block;
}

.user-circle, .help-circle{
  height: 25px;
  width: 25px;
  background-color: #015CAB;
  border-radius: 50%;
  margin-right:3px;
}

.help-circle{
    height:20px;
    width:20px;
}

.user-avatar, .help-avatar{
    align-items: center;
    display: flex;
}
.user-circle, .username{
    display:table-cell;
}

.username{
    padding-left: 3px;
    vertical-align:middle;
}


#user-avatar-initials {
    color: #fff;
    font-family: sans-serif;
    top: 10px;
    left: 21px;
    transform: translate(-50%, -50%);
    z-index: 30;
    display: table-cell;
    position: relative;
}

#help-avatar-mark {
    color: #fff;
    font-family: sans-serif;
    top: 14px;
    left: 20px;
    transform: translate(-50%, -50%);
    z-index: 30;
    display: table-cell;
    position: relative;
    font-weight: 700;
    font-size: large;
}


.profile-building{
    font-size:16px;
}

.profile-modal, .profile-modal-member{
    float:right;
    height:100vh;
    width:40%;
    margin:0;
}

.profile-modal-member{
    width:55%;
    max-width:1500px;
}

@media only screen and (max-width:1024px){
    .profile-modal-member{ 
       width:90%;
   }
}


.fa-cogs, .fa-lock, .fa-sign-out{
    margin-right:4px;
}

.profile-details-extra{
    position:relative;
    top:20px;
    right:160px;
}

.navbar .notification {
    border: 1px solid #fff;
    font-size: 15px;
    background: #f44336;
    color: #fff;
    min-width: 24px;
    padding: 0 5px;
    height: 21px;
    border-radius: 10px;
    text-align: center;
    line-height: 19px;
    vertical-align: middle;
    display: inline;
    position: absolute;
    bottom: 17px;
    left: 41px;
}

.fa-bell-o{
    font-size:25px;
}

#screen-header .col-lg-1{
    padding-left:0px;
    padding-right:0px;
}