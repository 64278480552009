.modal-content {
    background-color: #f5f5f5
}

.modal-header {
    background-color: #ffffff;
    padding: .4rem;
   
    display:initial;
    text-align:center;
}

.alert-header {
    border-bottom: 1px solid #DB9500;
}

.error-header {
    border-bottom: 1px solid #D40F0F;
}

.success-header {
    border-bottom: 1px solid #24A824;
}

.fa-exclamation-circle{
    color: #DB9500;
    margin-right:2px;
}

.fa-times-circle{
    color:#D40F0F;
    margin-right:2px;
}

.fa-check-circle{
    color:#24A824;
    margin-right:2px;
}

.modal-header button{
    color: #015CAB;
    opacity:1;
}

.modal-footer {
    justify-content: center;
    padding:.5rem;
    border-top:none;
}

.unauthorized-modal-dialog{
    width:80%;
    height:230px;
    
}
.unauthorized-modal-dialog h3{
    font-size:24px;
    font-weight:550;
    margin-bottom:10px;
}

.unauthorized-modal-dialog p{
    font-size:14px;
    margin:0;
}

.unauthorized-modal-backdrop, .unauthorized-modal{
    z-index:900;
}
