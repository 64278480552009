.no-beacon .okta-sign-in-header {
    display: none;
  }
  
  div.auth-content-inner a.link.js-help {
    display: none;
  }
  
  #okta-sign-in {
    margin-top: 20px;
  }