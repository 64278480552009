

#footer button, #footer a {
    color: #F5F5F5;
}



#footer button {
    display: block;
    text-align: center;
    text-decoration: none;
}

#footer button {
    background-color: #034681;
    border-style:none;
    padding-top:15px;
    background:none;
    border:none;
    padding:0;
}

#footer i {
    font-family: FontAwesome;
    font-size: 14px;
    margin-right: 5px;
}

#footer {
    background-color: #003366;
    padding: 0px 1.875rem 0px 1.875rem;
    vertical-align: middle;
    position: fixed;
    bottom: 0;
    line-height: 50px;
    font-family: sans-serif;
    font-size: 14px;
    color: #F8F9FA;
    z-index: 10;
    margin-left:60px;
    font-weight:400;
}


#copyright {
    margin-right: 60px;
    text-align: right;
    flex:1 0 auto;
}

#footer .footer-link{
    margin-left:0;
    margin-right: 1.25rem;
}