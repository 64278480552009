

.sticky {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 5px;
    text-align: center;
    background: #ffffff;
   
}

.otherFooter {
    margin-left: 9%;
    margin-right: 9%;
}
