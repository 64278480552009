.enabledllink {
    text-decoration: none;
    background-color: #1d3f74;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    color: white;
    display: block;
    text-align: left;
    text-indent: 25px;
    /*font-weight: bold;*/
    /*border: 3px solid white;*/
    padding: 5px 0px 5px 0px;
}

    .enabledllink:hover {
        /* background-color: #f5f5f5;
        padding: 5px 0px 5px 0px;
        text-decoration: none; */
        /*border: 1px solid #de6b00;*/
        /* margin-left: 15px;
        border-radius: 5px;
        text-indent: 10px; */
        color:#f5f5f5;
    }

.enabledhllink {
    text-decoration: none;
    background-color: #1d3f74;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    color: white;
    display: block;
    text-align: left;
    text-indent: 25px;
    /*font-weight: bold;*/
    /*border: 3px solid white;*/
    padding: 5px 0px 5px 0px;
}

.enabledhllink:hover {
    /* background-color: #f5f5f5;

    padding: 5px 0px 5px 0px;
    text-decoration: none;
    margin-left: 15px;
    border-radius: 5px;
    text-indent: 10px; */
    color:#f5f5f5;
}

.enabledlink 
{
    color: #0366d6;
}

.disabledlink {
    color: gray; 
    
}

.disabledlink:active {
	pointer-events: none;
}

.disabledlink a{
    color: gray;
}

.disabledllink {
    text-decoration: none;
    background-color: #1d3f74;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    display: block;
    text-align: left;
    text-indent: 25px;
    /*font-weight: bold;*/
    /*border: 3px solid white;*/
    padding: 5px 0px 5px 0px;
    background-color: #f5f5f5;
    pointer-events: none;
}

.hidelink {
    display:none;
}


.spanhidebr {
    display: none;
}

.contentheader {
    font-size: 52px;
    color: #6c6a5e;
    /*font-family: 'Lato-Light';*/
}

.contentbody {
    color: #034681;
    /*font: normal 18px/30px 'Lato-Regular';*/
    /*margin: 0 0 10px;*/
}

.temp {
    display: none;
}

.contentmenu {
    background-color: #1d3f74;
    padding: 0px 0px;
    padding-top:15px;
}

.content-row .card{
    margin-top:15px;
    margin-bottom:15px;
    color: #2A2E32
}

.content-row .card-title{
    font-weight: bold;
}

.content-banner, .content-subheading{
    margin:15px;
    width:97%;
}

.content-subheading{
    /* -webkit-columns: 40px 2;  */
    /* Chrome, Safari, Opera */ 
    /* -moz-columns: 60px 2;  */
    /* Firefox */ 
    /* columns: 60px 2;  */
    color: #2A2E32

}

.cardless .card{
    background-color: #F5F5F5;
    border-style: none;
}

.content-highlighted{
    background-color: #f5f5f5;
    /*border: 1px solid #de6b00;*/
    padding: 5px 0px 5px 0px;
    text-decoration: none;
    margin-left: 15px;
    border-radius: 5px;
    text-indent: 10px;
    color: #2A2E32
}

.contentmenu .content-highlighted:hover{
    color: #2A2E32
}


.inline{
    display:inline;
}

.contentbody td{
    border-collapse:collapse;
    border:1px solid #2A2E32;
    padding:5px;
}

.contentbody table{
    margin-left:auto;
    margin-right:auto;
}