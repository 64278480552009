ul.options {
    display: block;
    list-style: none;
    transition: width 0.3s;
    padding: 0;
    position: absolute;
    z-index: 1;
    top: 68px;
    width: 95%;
}

    ul.options li {
        display: block;
        background: white;
        padding: 10px;
        width: 100%;
        border-radius: 2px;
    }
        ul.options li:hover {
            /*font-weight: bold;*/
            cursor: pointer;
            background-color: #007bff;
            color:white;
        }

        /*ul.options li.option-active {
            background: #007bff;
        }*/
.loading{

}
.loader {
    font-size: 10px;
    text-indent: -9999em;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: absolute;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    bottom: 11px;
    right: 32px;
}

    .loader:before {
        width: 50%;
        height: 50%;
        background: #007bff;
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
    }

    .loader:after {
        background: #ffffff;
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.no-options {
    color: white;
}