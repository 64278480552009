.profile-form {
    margin-top:1%;
    align-content:center;
    justify-content:center;
}
.doc-upload-form{
    margin-top:1%;
    margin-left:2%;
}
.profile-form form{
    width:80%;
}

.profile-form .profile-member{
    width:100%;
}

.upload-modal-header{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

.highlight{
    border-color:orange;
}

.profile-close{

    color: #0366d6;
    position: relative;
    left: 95%;
    font-size: 20px;
}
.profile-name{
    display:block;
    font-size:25px;
    font-weight:600;
    padding-left:45px;
}



.profile-form .cancel{
    background-color:#f5f5f5;
    color:#0366d6;
    border-color:#0366d6;
    border-width:1px;
    border-style:solid;
}

.profile-member-grid-label{
    font-weight:600;
    font-size:16px;
}