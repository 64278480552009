.rpr-link{
    cursor:pointer;
}

.rpr-link:hover {
    text-decoration: underline;
}

.clickable-option {
    cursor: pointer;
    color: #015cab;
}

.RebatePacketHeader {
    /*color: #015CAB;*/
}

.backToFolders {
    cursor: pointer;
    font-size:16px;
}
