.loading-dot {
    background-color: #003366;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    animation: scale 1.75s ease-in-out infinite;
}

.delayed-animation {
    animation-delay: 0.58s;
}

.slower-delayed-animation {
    animation-delay: 1.16s;
}

.loading-dot-wrapper {
    margin-left: 7px;
    margin-right: 7px;
}

.loading-dot-container {
    position: relative;
    top: 50%;
    display: flex;
    justify-content: center;
}

@keyframes scale {
    50% {
        transform: scale(.1);
    }
}

.loading-overlay {
    position: fixed; /* Sit on top of the page content */
    display: none;
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(121, 119, 107, 0.5);
    z-index: 200000; /* Specify a stack order in case you're using a different order for other elements */
    cursor: wait;
}
